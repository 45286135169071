import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHAIN,
  TonConnectButton,
  TonConnectUIProvider,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import Section from "../components/section";
import CollectionCreateForm from "../components/collectionCreateForm";
import NftMintForm from "../components/nftMintForm";
import TonConnectBtn from "../components/connectBtn";
import { tonMainfestUrl } from "../config";
import { useTonConnect } from "../hooks/useTonConnect";
import TonInfo from "../components/info";
import { useParams } from "react-router-dom";
import DisplayAddressList from "../components/displayAddress";
import Header from "../components/header";
import { ItemDataType, ItemFileData } from "../types";
import { backend } from "../services/backend";

const SingleItemPage = () => {
  const { network } = useTonConnect();
  const { item } = useParams();
  const [itemInfo, setItemInfo] = useState<ItemDataType | null>(null);
  const [nftContent, setNftContent] = useState<ItemFileData | null>(null);

  if (!item) {
    return (
      <div className="app">
        <div className="snftBody">
          <Header />
          <div className="snftContent">
            <Section>
              <h1>Invalid item</h1>
            </Section>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    const fetchCollectionData = async () => {
      backend
        .getItemData(item)
        .then((data) => {
          setItemInfo(data);
          const contentUrl = data?.content;
          console.log("Url:", contentUrl, data)
          if (contentUrl)
            fetch(contentUrl)
              .then((res) => res.json())
              .then((res: ItemFileData) => {
                console.log("Received:", res)
                setNftContent(res)
              });
        })
        .catch((err) => alert(err));
    };
    fetchCollectionData();
  }, []);

  if (!itemInfo) {
    return (
      <div className="app">
        <div className="snftBody">
          <Header />
          <div className="snftContent">
            <Section>
              <h1>Loading...</h1>
            </Section>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <div className="snftBody">
        <div className="snftHeading">
          {`Item: ${item}`}
          <p>{network === CHAIN.TESTNET ? "testnet" : "mainnet"}</p>
        </div>
        <div className="snftContent">
          <Section>
            <h2>{nftContent?.name}</h2>
            <p>{nftContent?.description}</p>
            <p>Collection: {itemInfo?.collection_address}</p>
            <img src={nftContent?.image} />
          </Section>
        </div>
        <DisplayAddressList />
      </div>
    </div>
  );
};

export default SingleItemPage;
