import React, { ChangeEvent, useEffect, useState } from "react";
import MintCollectionBtn, { MintCollectionData } from "./mintCollectionBtn";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";
import { CollectionDataUrls, CollectionMintEntry } from "../types";

const CollectionCreateForm = () => {
  const [formData, setFormData] = useState<MintCollectionData>({});
  const [collectionPhotoUrl, setCollectionPhotoUrl] = useState<string | null>(
    null
  );
  const [collectionBackgroundUrl, setCollectionBackgroundUrl] = useState<
    string | null
  >(null);
  const [itemPhotoUrl, setItemPhotoUrl] = useState<string | null>(null);
  const [collectionName, setCollcetionName] = useState<string>("");
  const [collectionDesc, setCollcetionDesc] = useState<string>("");

  const updateFormData = (param: keyof MintCollectionData) => {
    return (event: any) => {
      setFormData((prevData) => ({
        ...prevData,
        [param]: event.target.value || "",
      }));
    };
  };

  const fileBackendLoad = async (
    event: ChangeEvent<HTMLInputElement>,
    target: "collectionPhotoUrl" | "collectionBackgroundUrl" | "itemPhotoUrl"
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(`${BACKEND_URL}files/loadphoto`, {
          method: "POST",
          body: formData,
        });
        if (!response.ok) {
          throw new Error("File upload failed");
        }
        const result = await response.json();
        if (target === "collectionPhotoUrl") {
          setCollectionPhotoUrl(result.url);
        } else if (target === "collectionBackgroundUrl") {
          setCollectionBackgroundUrl(result.url);
        } else if (target === "itemPhotoUrl") {
          setItemPhotoUrl(result.url);
        }
        console.log("File uploaded successfully:", result.url);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const saveCollectionEntry = async () => {
    if (!collectionName) return;
    const collectionMintData: CollectionMintEntry = {
      collection: {
        name: collectionName,
        description: collectionDesc,
        imageUrl: collectionPhotoUrl || "",
        backgroundUrl: collectionBackgroundUrl || "",
        social_links: [],
      },
      item: {
        image: itemPhotoUrl || "",
      },
    };
    const response = await fetch(`${BACKEND_URL}files/loadcollection`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        entry: collectionMintData,
      }),
    });
    const result: CollectionDataUrls = await response.json();
    alert("Loaded");
    updateFormData("dataFile")({ target: { value: result.collectionFileUrl } });
    updateFormData("itemFile")({ target: { value: result.itemFileUrl } });
  };

  return (
    <div className="form">
      <div className="formActionRow">
        <div className="formColumn columnStep1">
          <p>Collection photo file:</p>
          <input
            id="collectionPhoto"
            type="file"
            onChange={(e) => fileBackendLoad(e, "collectionPhotoUrl")}
          />
          {collectionPhotoUrl && (
            <div>
              <p>File uploaded successfully:</p>
              <a
                href={collectionPhotoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {collectionPhotoUrl}
              </a>
            </div>
          )}
          <p>Collection background file:</p>
          <input
            id="collectionBkg"
            type="file"
            onChange={(e) => fileBackendLoad(e, "collectionBackgroundUrl")}
          />
          {collectionBackgroundUrl && (
            <div>
              <p>File uploaded successfully:</p>
              <a
                href={collectionBackgroundUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {collectionBackgroundUrl}
              </a>
            </div>
          )}
          <p>Nft item file:</p>
          <input
            id="collectionBkg"
            type="file"
            onChange={(e) => fileBackendLoad(e, "itemPhotoUrl")}
          />
          {itemPhotoUrl && (
            <div>
              <p>File uploaded successfully:</p>
              <a href={itemPhotoUrl} target="_blank" rel="noopener noreferrer">
                {itemPhotoUrl}
              </a>
            </div>
          )}
          <p>Collection name:</p>
          <input
            type="text"
            value={collectionName}
            onChange={(e) => setCollcetionName(e.target.value)}
          />
          <p>Collection description:</p>
          <input
            type="text"
            value={collectionDesc}
            onChange={(e) => setCollcetionDesc(e.target.value)}
          />
          <div className="btn" onClick={saveCollectionEntry}>
            Load collection data
          </div>
        </div>
        <div className="formColumn columnStep2">
          <p>Collection data url: </p>
          <input
            type="text"
            value={formData.dataFile}
            onChange={updateFormData("dataFile")}
          />
          <p>Item data url: </p>
          <input
            type="text"
            value={formData.itemFile}
            onChange={updateFormData("itemFile")}
          />
          <p>Mint limit: </p>
          <input
            type="number"
            value={formData.mintLimit}
            onChange={updateFormData("mintLimit")}
          />
          <p>Price in TON: </p>
          <input
            type="text"
            value={formData.price}
            onChange={updateFormData("price")}
          />
          <p>Royalty %: </p>
          <input
            type="text"
            value={formData.royaltyPercent}
            onChange={updateFormData("royaltyPercent")}
          />
          <MintCollectionBtn data={formData} />
        </div>
      </div>
    </div>
  );
};

export default CollectionCreateForm;
