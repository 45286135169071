import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHAIN,
  TonConnectButton,
  TonConnectUIProvider,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import Section from "../components/section";
import CollectionCreateForm from "../components/collectionCreateForm";
import NftMintForm from "../components/nftMintForm";
import TonConnectBtn from "../components/connectBtn";
import { tonMainfestUrl } from "../config";
import { useTonConnect } from "../hooks/useTonConnect";
import TonInfo from "../components/info";
import WithdrawBtn from "../components/withdrawBtn";
import MintItemBtn from "../components/mintItemBtn";
import { useParams } from "react-router-dom";
import Header from "../components/header";
import DisplayAddressList from "../components/displayAddress";
import { useCollectionContract } from "../hooks/useCollectionContract";
import { hexToString } from "../utils/mint";
import { getCollectionDataFromBackend } from "../functions/mintCollection";
import { CollectionStandardData } from "../types";

const SingleCollectionPage = () => {
  const { network } = useTonConnect();
  const { collection } = useParams();
  const [collectionInfo, setColllectionInfo] =
    useState<CollectionStandardData | null>(null);

  if (!collection) {
    return (
      <div className="app">
        <div className="snftBody">
          <Header />
          <div className="snftContent">
            <Section>
              <h1>Invalid collection</h1>
            </Section>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    const fetchCollectionData = async () => {
      getCollectionDataFromBackend(collection)
        .then((data) => {
          setColllectionInfo(data.standardData)
        })
        .catch((err) => alert(err));
    };
    fetchCollectionData();
  }, []);

  console.log(collectionInfo, collectionInfo?.cover_image, collectionInfo?.image)

  return (
    <div className="app">
      <div className="snftBody">
        <Header />
        <div className="imgBlock">
          <p>Collection background:</p>
          <img src={collectionInfo?.cover_image} width={900} height={200} />
          <p>Collection image:</p>
          <img src={collectionInfo?.image} width={200} height={200} />
        </div>
        <div className="snftContent">
          <Section>
            <MintItemBtn collection={collection} />
          </Section>
        </div>
        <DisplayAddressList />
      </div>
    </div>
  );
};

export default SingleCollectionPage;
