import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./state/store";
import App from "./app";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { tonMainfestUrl } from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <TonConnectUIProvider manifestUrl={tonMainfestUrl}>
      <Provider store={store}>
        <App />
      </Provider>
    </TonConnectUIProvider>
  </React.StrictMode>
);
