import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import "./scss/app.scss";

import CollectionCreatePage from "./pages/createCollection";
import SingleCollectionPage from "./pages/collection";
import MainPage from "./pages/mainPage";
import AccountPage from "./pages/account";
import SingleItemPage from "./pages/item";
import ConfirmWalletPage from "./pages/confirmWallet";
import { masterTonContract } from "./config";


const App = () => {

  useEffect(() => {
    alert(`Master: ${masterTonContract}`)
  }, [])

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/walletconfirm" element={<ConfirmWalletPage />}/>
          <Route path="/owner/:address" element={<AccountPage />}/>
          <Route path="/collection/create" element={<CollectionCreatePage />}/>
          <Route path="/collection/:collection" element={<SingleCollectionPage />} />
          <Route path="/item/:item" element={<SingleItemPage />} />
          <Route path="/" element={<MainPage />} />
        </Routes>
    </BrowserRouter>
  );
};

export default App;
