import { BACKEND_URL } from "../config";
import { CollectionMasterStandardData, CollectionStandardData, CollectionTotalData, MasterStandardData } from "../types";

export async function mintNftCollection (data: {
    mintLimit: number,
    tonPrice: number,
    owner: string
}) {
    
}

export async function getCollectionDataFromBackend (address: string): Promise<CollectionTotalData> {
    const dataUrl = `${BACKEND_URL}collectiondata/${address}`;
    return new Promise (async (resolve, reject) => {
        const collectionDataRequest = await fetch(dataUrl);
        if (collectionDataRequest.status !== 200) {
            reject("Failed to get data");
            return;
        }
        const fastServerRequestResult = await collectionDataRequest.json();
        const collectionDataUrl = fastServerRequestResult.collection?.contentUrl;
        if (!collectionDataUrl) {
            reject("Collection data unknown")
            return;
        }
        const collectionInfoRequest = await fetch(collectionDataUrl);
        if (collectionInfoRequest.status !== 200) {
            reject("Failed to get collection info")
            return;
        }
        const collectionInfo: CollectionStandardData = await collectionInfoRequest.json();
        const result: CollectionTotalData = {
            standardData: collectionInfo,
            nextItemIndex: fastServerRequestResult.collection?.nextItemIndex,
            nextItemAddress: fastServerRequestResult.collection?.nextItemAddress
        }
        resolve(result);
    })
}

export async function getCollectionMasterData (address: string): Promise<CollectionMasterStandardData> {
    const dataUrl = `${BACKEND_URL}collectionmaster/${address}`;
    return new Promise (async (resolve, reject) => {
        const collectionDataRequest = await fetch(dataUrl);
        if (collectionDataRequest.status !== 200) {
            reject("Failed to get data");
            return;
        }
        const fastServerRequestResult = await collectionDataRequest.json();
        const collectionInfo: CollectionMasterStandardData = fastServerRequestResult.data;
        resolve(collectionInfo);
    })
}

export async function getMasterData (address: string): Promise<MasterStandardData> {
    const dataUrl = `${BACKEND_URL}master/${address}`;
    return new Promise (async (resolve, reject) => {
        const collectionDataRequest = await fetch(dataUrl);
        if (collectionDataRequest.status !== 200) {
            reject("Failed to get data");
            return;
        }
        const fastServerRequestResult = await collectionDataRequest.json();
        const collectionInfo: MasterStandardData = fastServerRequestResult.data;
        resolve(collectionInfo);
    })
}

