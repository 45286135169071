import React, { useEffect, useMemo, useState } from "react";
import { theOpenNetworkAuth, tonPreconnect } from "../functions";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useCollectionContract } from "../hooks/useCollectionContract";
import { Address } from "@ton/core";
import { CollectionMintData, useMasterContract } from "../hooks/useMasterContract";
import { useTonConnect } from "../hooks/useTonConnect";
import { sleep } from "../utils/common";

export interface MintCollectionData {
    dataFile?: string, 
    itemFile?: string, 
    price?: number, 
    mintLimit?: number, 
    royaltyPercent?: number
}
const MintCollectionBtn = (props: {data: MintCollectionData}) => {

    const [pending, Pending] = useState(false);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const {network, wallet, sender } = useTonConnect();
    const masterContract = useMasterContract()

    const mintAction = async () => {
        console.log("Mint called #3");
        Pending(true);
        const collectionData = await masterContract.newCollectionData();
        await sleep(1101);
        console.log("Sleep passed")
        if (!collectionData.address) {
          alert("Collection data not found!");
          Pending(false);
          return;
        }
        if (!wallet) {
          alert("Wallet not connected!");
          Pending(false);
          return;
        }
        const dataToSend: CollectionMintData = {
          collectionDataUrl: props.data.dataFile || "",
          itemDataUrl: props.data.itemFile || "",
          item_price: props.data.price || 0,
          mint_limit: props.data.mintLimit || 0,
          owner_address: wallet,
          royaltyParams: {
            numenator: Math.min(props.data.royaltyPercent || 0, 100),
            denumenator: 1000,
            destination: wallet
          }
        }
        try {
          await masterContract.mintCollection(dataToSend);
          alert(`Remember your future address: ${collectionData.address}`)
          Pending(false);
          return;
        } catch(e) {
          alert(e);
          Pending(false);
          return;
        }
    }

    return (
      <>
        <div className="btn" onClick={mintAction}>
            Create collection
        </div>
      </>
    );
  };
  
  export default MintCollectionBtn;

// const [tonConnectUI, setOptions] = useTonConnectUI();