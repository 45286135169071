import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHAIN, TonConnectButton, TonConnectUIProvider, useTonConnectUI } from "@tonconnect/ui-react";
import Section from '../components/section';
import CollectionCreateForm from '../components/collectionCreateForm';
import NftMintForm from '../components/nftMintForm';
import TonConnectBtn from '../components/connectBtn';
import { tonMainfestUrl } from '../config';
import { useTonConnect } from '../hooks/useTonConnect';
import DisplayAddressList from '../components/displayAddress';
import Header from '../components/header';

const AccountPage = () => {

  const {network} = useTonConnect()

  return (
    <div className="app mainPage">
         <div className="snftBody">
            <Header />
            <div className="pageInner">
               Will be an owner page
            </div>
            <DisplayAddressList />
         </div>
    </div>
    )
}

export default AccountPage