import React from "react";
import { UrlItem } from "../types";

const PageLinkSection = (props: UrlItem) => {
  return (
    <div className="pageLinkSection">
      <div className="blockInner">
        {props.img ? (
          <div className="blockInnerItem">
            <img src={props.img} width="100" height="100" />
          </div>
        ) : null}
        <div className="blockInnerItem">
          <a href={props.url}>{props.title}</a>
        </div>
        {props.description ? (
          <div className="blockInnerItem">
            <p>{props.description}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PageLinkSection;
