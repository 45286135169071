import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { getSignableMessage } from '../utils/common';
import { useTonConnect } from '../hooks/useTonConnect';
import { useTonClient } from '../hooks/useTonCLient';

const ConfirmWalletPage = () => {

    const {connected, wallet} = useTonConnect();
    const { client } = useTonClient();
    
    const signMessage = () => {
         const msg = getSignableMessage();
         console.log("Msg:", msg)
    }

    return (
      <div className="app mainPage">
           <div className="snftBody">
              <Header />
              <div className="pageInner">
                 <div>Sign message to confirm wallet</div>
                 <div><button onClick={signMessage}>Sign message</button></div>
              </div>
           </div>
      </div>
      )
  }
  
  export default ConfirmWalletPage;