import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHAIN, TonConnectButton, TonConnectUIProvider, useTonConnectUI } from "@tonconnect/ui-react";
import Section from '../components/section';
import CollectionCreateForm from '../components/collectionCreateForm';
import { useTonConnect } from '../hooks/useTonConnect';
import Header from '../components/header';
import DisplayAddressList from "../components/displayAddress";

const CollectionCreatePage = () => {

  const {network, wallet } = useTonConnect();

  return (
    <div className="app">
         <div className="snftBody">
            <Header />
            <div className="snftContent">
            <Section>
              <h3>Create NFT collection:</h3>
                <CollectionCreateForm />
            </Section>
            </div>
            <DisplayAddressList />
         </div>
    </div>
  );
};

export default CollectionCreatePage