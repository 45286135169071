import { LocalUserData, NftInfo } from "../types";

export const localStorageItemName = "USER_HISTORY";
export const storageUpdateEvent = "storageUpdate";
export const defaultStorage: LocalUserData = {
    wallets: [],
    nfts: [],
    collections: []
}

const updateEvent = new Event(storageUpdateEvent);

export function getUserAddressHistory(): LocalUserData {
    const data = localStorage.getItem(localStorageItemName);
    return data ? JSON.parse(data) : defaultStorage;
}

export function addWallet(wallet: string): void {
    const userData = getUserAddressHistory();
    if (!userData.wallets.includes(wallet)) {
        userData.wallets.push(wallet);
        localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    }
    window.dispatchEvent(updateEvent)
}

export function addNft(nft: NftInfo): void {
    const userData = getUserAddressHistory();
    const exists = userData.nfts.some(existingNft => 
        existingNft.address === nft.address && existingNft.collection === nft.collection
    );
    if (!exists) {
        userData.nfts.push(nft);
        localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    }
    window.dispatchEvent(updateEvent)
}

export function addCollection(collection: string): void {
    const userData = getUserAddressHistory();
    if (!userData.collections.includes(collection)) {
        userData.collections.push(collection);
        localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    }
    window.dispatchEvent(updateEvent)
}

export function removeWallet(wallet: string): void {
    const userData = getUserAddressHistory();
    const updatedWallets = userData.wallets.filter(existingWallet => existingWallet !== wallet);
    userData.wallets = updatedWallets;
    localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    window.dispatchEvent(updateEvent)
}

export function removeNft(nft: NftInfo): void {
    const userData = getUserAddressHistory();
    const updatedNfts = userData.nfts.filter(existingNft =>
        !(existingNft.address === nft.address && existingNft.collection === nft.collection)
    );
    userData.nfts = updatedNfts;
    localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    window.dispatchEvent(updateEvent)
}

export function removeCollection(collection: string): void {
    const userData = getUserAddressHistory();
    const updatedCollections = userData.collections.filter(existingCollection => existingCollection !== collection);
    userData.collections = updatedCollections;
    localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    window.dispatchEvent(updateEvent)
}
