import React, { useEffect, useMemo, useState } from "react";
import { theOpenNetworkAuth, tonPreconnect } from "../functions";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useCollectionContract } from "../hooks/useCollectionContract";
import { useParams } from "react-router-dom";

const MintItemBtn = (props: { collection: string }) => {
  const [pending, Pending] = useState(false);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const { mint } =
    useCollectionContract();

  const mintItem = async () => {
    if (pending || !props.collection) return;
    Pending(true);
    await mint(props.collection);
    Pending(false);
  };

  if (!props.collection) {
    return null
  }

  return (
    <>
      <div className="btn connectBtn" onClick={mintItem}>
        <p>{"Mint item"}</p>
      </div>
    </>
  );
};

export default MintItemBtn;

// const [tonConnectUI, setOptions] = useTonConnectUI();
