import { useEffect, useState } from "react";
import { Address, beginCell, Cell, fromNano, toNano, OpenedContract } from "@ton/core";
import { useAsyncInitialize } from "./useAsyncInitialize";
import { useTonClient } from "./useTonCLient";
import { useTonConnect } from "./useTonConnect";
import { addNft } from "../utils/storage";
import { sleep } from "../utils/common";
import { SimpleNftCollection } from "../contracts/SimpleNftMaster/tact_SimpleNftCollection";
import { getCollectionDataFromBackend, getCollectionMasterData } from "../functions/mintCollection";

export function useCollectionContract() {
    const { client } = useTonClient();
    const { wallet, sender } = useTonConnect();
    const [balance, setBalance] = useState<string | null>(null);

    // Collection hook
    const customCollection = (address: string) => {
        return useAsyncInitialize(async () => {
            if (!client || !wallet) return;

            const contract: any = SimpleNftCollection.fromAddress(Address.parse(address));
            return client.open(contract) as OpenedContract<SimpleNftCollection>;
        }, [client, wallet]);
    };

    const getMasterData = async (address: string) => {
        try {
            const data = await customCollection(address)?.getGetMasterData();
            return data;
        } catch (e: any) {
            return null;
        }
    };

    const getNftAddressByIndex = (address: string, index: number) => {
        return customCollection(address)?.getGetNftAddressByIndex(BigInt(index));
    };

    const getCollectionData = (address: string) => {
        return customCollection(address)?.getGetCollectionData();
    };

    const getNftContent = (address: string, index: number) => {
        return customCollection(address)?.getGetNftContent(BigInt(index), new Cell({}));
    };

    // Restructuring mint to avoid hooks inside async functions
    const mint = async (address: string) => {
        console.log("Mint item called, collection:", address);

        if (!wallet) {
            return false;
        }

        try {
            const contract = SimpleNftCollection.fromAddress(Address.parse(address));
            const opened = client?.open(contract) as OpenedContract<SimpleNftCollection>;
            const masterData = await getCollectionMasterData(address);
            console.log("Owner address: ", wallet, Address.parse(wallet));
            console.log("Master data: ", masterData);

            const collectionData = await  getCollectionDataFromBackend(address)
            const nextNftAddress = collectionData
                ? collectionData.nextItemAddress
                : null;
            await sleep(1101);
            console.log("Nft address info:", collectionData, nextNftAddress);

            await opened?.send(sender, { value: BigInt(masterData.price) }, "Mint");
            await sleep(1101);
            addNft({
                collection: address,
                address: nextNftAddress?.toString() || ""
            });

            return true;
        } catch (e) {
            console.log("Error: ", e);
            return false;
        }
    };

    return {
        balance,
        getMasterData,
        getNftAddressByIndex,
        getNftContent,
        getCollectionData,
        mint
    };
}