import React, { useEffect, useMemo, useState } from "react";
import { theOpenNetworkAuth, tonPreconnect } from "../functions";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useMasterContract } from "../hooks/useMasterContract";
import { useTonConnect } from "../hooks/useTonConnect";


const WithdrawBtn = () => {

    const [pending, Pending] = useState(false);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const { wallet, sender } = useTonConnect();
    const { withdraw } = useMasterContract();

    const makeWithdraw = async () => {
        console.log(wallet);
        console.log(sender)
        if (pending || !wallet) return;
        Pending(true);
        await withdraw(wallet);
        Pending(false);
    }

    return (
      <div className="btn connectBtn" onClick={makeWithdraw}>
           <p>
             {"Withdraw assets"}
           </p>
      </div>
    );
  };
  
  export default WithdrawBtn;

// const [tonConnectUI, setOptions] = useTonConnectUI();