import React, { useEffect, useMemo, useState } from "react";
import { LocalUserData } from "types";
import { getUserAddressHistory, storageUpdateEvent } from "../utils/storage";

const DisplayAddressList = () => {
  const [addressInfo, setAddressInfo] = useState<LocalUserData>();
  const loadAddress = () => {
    setAddressInfo(getUserAddressHistory());
  };

  useEffect(() => {
    loadAddress();
    window.addEventListener(storageUpdateEvent, loadAddress);
    return window.removeEventListener(storageUpdateEvent, loadAddress);
  }, []);

  return (
    <div className="storageAddressInfo">
      <div className="inner">
        <div className="infoBlock collections">
          <h4>Collections:</h4>
          {addressInfo?.collections.map((item, index) => {
            return <p key={`cl${index * 2.302}`}>{item}</p>;
          })}
        </div>
        <div className="infoBlock items">
          <h4>Nfts:</h4>
          {addressInfo?.nfts.map((item, index) => {
            return (
              <p
                key={`cl${index * 2.302}`}
              >{`${item.address}, collcetion: ${item.collection}`}</p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DisplayAddressList;
